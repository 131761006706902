body {
  font-family: "Fira Sans", sans-serif !important;
}

aside {
  font-family: "Fira Sans", sans-serif !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Fira Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fira Sans", sans-serif !important;
}

.stats-container {
  /* padding: 0 1rem; */
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
  grid-gap: 0.5rem;
  margin-bottom: 2rem;
}

.stat {
  display: grid;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background: #ffffff;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); */
  box-shadow: 0 2px 0 rgb(90 97 105 / 11%), 0 4px 8px rgb(90 97 105 / 12%), 0 10px 10px rgb(90 97 105 / 6%),
    0 7px 70px rgb(90 97 105 / 10%);
  padding: 3rem;
  border-radius: 5px;
}

.stat > p {
  margin-bottom: 1rem;
  color: rgb(68, 65, 65);
}

.stat > span {
  font-size: 1.5rem;
  letter-spacing: 1px;
  color: rgb(14, 7, 7);
}

.stat > * {
  text-align: center;
}

.login-card {
  font-family: "Fira Sans", sans-serif;
  position: absolute;
  top: 20vh;
  background-color: #fff;
  padding: 2rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.login-form {
  width: 17rem;
  padding-bottom: 1rem;
}

.btn-sm-block {
  display: block;
  width: 100%;
  margin-top: 1rem;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
  border-radius: 5px;
}

.table-responsive {
  display: block;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
}

.transaction-filters {
  height: auto;
}
.empty-list {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  padding: 6rem;
}
.empty-list-img {
  display: grid;
  justify-content: center;
}
.empty-list p {
  align-self: start;
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 16px;
}

.modal-backdrop {
  background-color: #7a7d80 !important;
}

.modal-backdrop.show {
  opacity: 0.12 !important;
}

.badge{
  min-width: 7rem;
}

.paginate-container {
  display: flex;
  /* justify-content: center; */
}

.paginate-container p {
  align-self: center;
}

.pagination {
  display: flex;
  font-size: 12px;
  font-family: inherit;
  flex-wrap: wrap;
 margin-left: auto;
 margin-bottom: 0;
}

.pagination li {
  display: grid;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-content: center;
  margin: 0 .5rem;
}

.paginate-active {
  background: #007bff;
  color: #fff;
  border-radius: 100%;
  box-shadow: 0 0.125rem 0.625rem rgb(90 97 105 / 12%);
}

.btn-primary a {
  color: #fff;
  text-decoration: none;
}

.main-sidebar .nav .nav-item, .main-sidebar .nav .nav-link {
  font-size: .90rem;
  font-family: "Fira Sans", sans-serif !important;
}

.main-sidebar .nav .nav-item .nav-link i.material-icons {
  font-size: 1.3rem;
}
.user-profile {
  padding: 1.2rem;
}

i.material-icons {
  font-size: 1rem;
}

@media screen and (min-width: 500px) {
  .login-form {
    width: 25rem;
  }

  .login-card {
    padding: 2rem;
  }

  .btn-sm-block {
    display: block;
    width: auto;
    margin-top: 0;
  }

  .table-responsive {
    display: inline-table;
  }

  .modal-dialog {
    top: 15vh !important;
  }
  .modal-backdrop.show {
    opacity: 0.12 !important;
  }
}

@media screen and (max-width: 500px) {
  .transaction-filters {
    display: grid;
    grid-gap: 0.5rem;
    height: auto;
  }

  .paginate-container p {
    display: none;
  }
}
